import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';

interface UserLog {
    id: number;
    action: string;
    userName: string;
    description: string;
    date: string;
}

const UserLogs: React.FC = () => {
    // Dummy data for user logs
    const dummyData: UserLog[] = [
        {
            id: 1,
            action: 'Login',
            userName: 'John Doe',
            description: 'User logged in successfully',
            date: '2024-12-01',
        },
        {
            id: 2,
            action: 'Update Profile',
            userName: 'Jane Smith',
            description: 'User updated their profile information',
            date: '2024-12-02',
        },
        {
            id: 3,
            action: 'Logout',
            userName: 'Michael Brown',
            description: 'User logged out',
            date: '2024-12-03',
        },
    ];

    const [data] = useState<UserLog[]>(dummyData);

    const columns: ColumnConfig[] = [
        { 
            field: 'action', 
            headerName: 'Action',
            width: 170,
            renderCell: (params: any) => (
                <span>
                    {params.row?.action || '-'}
                </span>
            ),
        },
        { 
            field: 'userName', 
            headerName: 'User Name',
            width: 200,
            renderCell: (params: any) => (
                <span>
                    {params.row?.userName || '-'}
                </span>
            ),
        },
        { 
            field: 'description', 
            headerName: 'Description',
            width: 470,
            renderCell: (params: any) => (
                <span>
                    {params.row?.description || '-'}
                </span>
            ),
        },
        { 
            field: 'date', 
            headerName: 'Date',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.date || '-'}
                </span>
            ),
        },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container flex justify-between items-center">
                    <Box className="flex items-center gap-2">
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            User Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={false}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserLogs;
