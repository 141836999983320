import React, { FC, ReactNode, useEffect, useState } from "react";
import { Sidenav, Nav } from "rsuite";
import GridViewIcon from '@mui/icons-material/GridView';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { useAuth } from "../utils/AuthContext";

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const [expanded] = useState(true);
  const [activeKey, setActiveKey] = useState<string>(() => {
    return localStorage.getItem("activeKey") || "1"; // Default to "1" for dashboard
  });
  const [userType, setUserType] = useState<string>("");
  const navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    const storedUserType = sessionStorage.getItem("userType");
    setUserType(storedUserType || "");
  }, []);

  const headerStyles = {
    height: "85%",
    width: "85%",
  };

  const imageContainer = {
    width: "80%",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "10px",
  };

  const portalTextStyles = {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#EC1B3B",
  };

  const navItemStyles = {
    fontSize: "17px",
    padding: "3% 0% 2.7% 5%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const disabledNavItemStyles = {
    ...navItemStyles,
    color: "#000",
    cursor: "not-allowed",
  };

  const iconStyles = {
    marginRight: "10px",
  };

  const logoutItemStyles = {
    ...navItemStyles,
    marginTop: "auto",
  };

  // Get portal type text based on userType
  const getPortalTypeText = () => {
    switch (userType) {
      case "admin":
        return "Admin Portal";
      case "vendor":
        return "Vendor Portal";
      case "analytics":
        return "Analytics Portal";
      default:
        return "";
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const getNavItemStyles = (key: string) => ({
    ...navItemStyles,
    color: activeKey === key ? "#EC1B3B" : "#000",
  });

  const handleSelect = (key: string) => {
    setActiveKey(key);
    localStorage.setItem("activeKey", key);

    switch (key) {
      case "1":
        navigate("/dashboard");
        break;
      case "2":
        navigate("/purchaseorder");
        break;
      case "5":
        navigate("/user-management");
        break;
      case "6":
        navigate("/reports");
        break;
      case "8":
        navigate("/aboutus");
        break;
      case "9":
        navigate("/contactus");
        break;
      case "10":
        navigate("/qr-history");
        break;
      case "11":
        handleLogout();
        break;
      case "7":
        navigate("/security-settings");
        break;
      default:
        break;
    }
  };

  const pagesInDevelopment = ["3", "4"];

  const renderNavItems = () => {
    if (userType === "analytics") {
      return (
        <>
          <Nav.Item eventKey="1" icon={<GridViewIcon style={iconStyles} />} style={getNavItemStyles("1")}>
            Dashboard
          </Nav.Item>
          <Nav.Item eventKey="6" icon={<DescriptionOutlinedIcon style={iconStyles} />} style={getNavItemStyles("6")}>
            Reports
          </Nav.Item>
        </>
      );
    }

    return (
      <>
        <Nav.Item eventKey="1" icon={<GridViewIcon style={iconStyles} />} style={getNavItemStyles("1")}>
          Dashboard
        </Nav.Item>
        <Nav.Item eventKey="2" icon={<ReceiptOutlinedIcon style={iconStyles} />} style={getNavItemStyles("2")}>
          Purchase Order
        </Nav.Item>
        <Nav.Item
          eventKey="3"
          icon={<InventoryOutlinedIcon style={iconStyles} />}
          style={pagesInDevelopment.includes("3") ? disabledNavItemStyles : getNavItemStyles("3")}
          onClick={pagesInDevelopment.includes("3") ? undefined : () => handleSelect("3")}
        >
          GRN Status
        </Nav.Item>
        <Nav.Item
          eventKey="4"
          icon={<RoomPreferencesOutlinedIcon style={iconStyles} />}
          style={pagesInDevelopment.includes("4") ? disabledNavItemStyles : getNavItemStyles("4")}
          onClick={pagesInDevelopment.includes("4") ? undefined : () => handleSelect("4")}
        >
          Quality Control
        </Nav.Item>
        {userType !== "vendor" && (
          <Nav.Item
            eventKey="5"
            icon={<AssignmentIndOutlinedIcon style={iconStyles} />}
            style={getNavItemStyles("5")}
          >
            User Management
          </Nav.Item>
        )}
        <Nav.Item eventKey="10" icon={<GridViewIcon style={iconStyles} />} style={getNavItemStyles("10")}>
          QR History
        </Nav.Item>
        <Nav.Item eventKey="6" icon={<DescriptionOutlinedIcon style={iconStyles} />} style={getNavItemStyles("6")}>
          Reports
        </Nav.Item>
        <Nav.Item
          eventKey="7"
          icon={<SettingsOutlinedIcon style={iconStyles} />}
          style={getNavItemStyles("7")}
          onClick={() => handleSelect("7")}
        >
          Security Settings
        </Nav.Item>
        <Nav.Item eventKey="8" icon={<InfoOutlinedIcon style={iconStyles} />} style={getNavItemStyles("8")}>
          About Us
        </Nav.Item>
        <Nav.Item eventKey="9" icon={<ContactSupportOutlinedIcon style={iconStyles} />} style={getNavItemStyles("9")}>
          Contact Us
        </Nav.Item>
      </>
    );
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <div
        style={{
          width: "20vw",
          minWidth: "15vw",
          background: "#F7F7FA",
          position: "fixed",
          top: 0,
          bottom: 0,
        }}
      >
        <Sidenav expanded={expanded} defaultOpenKeys={[]}>
          <Sidenav.Body>
            <Sidenav.Header>
              <div style={imageContainer}>
                <img style={headerStyles} src={logo} alt="Logo" />
                {userType && <div style={portalTextStyles}>{getPortalTypeText()}</div>}
              </div>
            </Sidenav.Header>
            <Nav activeKey={activeKey} onSelect={handleSelect}>
              {renderNavItems()}
              <Nav.Item eventKey="11" icon={<ExitToAppOutlinedIcon style={iconStyles} />} style={logoutItemStyles}>
                Logout
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>

      <div
        style={{
          marginLeft: "21vw",
          width: "80vw",
          padding: 0,
          overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;