import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import { getAccessRequestLogs } from '../../../utils/services/apiService';

interface AccessRequestLog {
    id: number;
    status: string;
    request_date?: string;
    approve_date?: string;
    qrBatch: {
        batch_id: number;
        purchaseOrder: {
            purchase_order_number: string;
        };
        gmProductSku: {
            item_code: string;
            description: string;
        };
    };
    user: {
        user_full_name: string;
    };
    admin: {
        user_full_name: string;
    };
}

const AccessRequestLogs: React.FC = () => {
    const [data, setData] = useState<AccessRequestLog[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAccessRequestLogs();
    }, []);

    const fetchAccessRequestLogs = async () => {
        try {
            const response = await getAccessRequestLogs();
            if (response.data) {
                setData(Array.isArray(response.data) ? response.data : [response.data]);
            }
        } catch (error) {
            console.error("Error fetching access request logs:", error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString?: string) => {
        if (!dateString) return '-';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    const columns: ColumnConfig[] = [
        { 
            field: 'status', 
            headerName: 'Status',
            width: 100,
            renderCell: (params: any) => (
                <span className="capitalize">
                    {params.row?.status || '-'}
                </span>
            )
        },
        { 
            field: 'request_date', 
            headerName: 'Request Date',
            width: 120,
            renderCell: (params: any) => (
                <span className="capitalize">
                    {formatDate(params.row?.request_date)}
                </span>
            )
        },
        { 
            field: 'approve_date', 
            headerName: 'Approved Date',
            width: 120,
            renderCell: (params: any) => (
                <span className="capitalize">
                    {formatDate(params.row?.approve_date)}
                </span>
            )
        },
        { 
            field: 'purchaseOrderNumber', 
            headerName: 'PO Number',
            width: 110,
            renderCell: (params: any) => (
                <span>
                    {params.row?.qrBatch?.purchaseOrder?.purchase_order_number || '-'}
                </span>
            )
        },
        { 
            field: 'itemCode', 
            headerName: 'Item Code',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.qrBatch?.gmProductSku?.item_code || '-'}
                </span>
            )
        },
        { 
            field: 'description', 
            headerName: 'Description',
            width: 280,
            renderCell: (params: any) => (
                <span>
                    {params.row?.qrBatch?.gmProductSku?.description || '-'}
                </span>
            )
        },
        { 
            field: 'userName', 
            headerName: 'Vendor Name',
            width: 150,
            renderCell: (params: any) => (
                <span>
                    {params.row?.user?.user_full_name || '-'}
                </span>
            )
        },
        { 
            field: 'adminName', 
            headerName: 'Approved By',
            width: 140,
            renderCell: (params: any) => (
                <span>
                    {params.row?.admin?.user_full_name || '-'}
                </span>
            )
        },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container flex justify-between items-center">
                    <Box className="flex items-center gap-2">
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            Request Access Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default AccessRequestLogs;