import React, { useState, useEffect } from 'react';
import './ExpiryPolicy.css';
import Header from '../../dashboard/components/Header';
import { fetchExpiryPolicy, updateExpiryPolicy } from '../../../utils/services/apiService';
import { toast } from 'react-toastify';
import BackButton from '../../../components/BackButton';


interface ExpiryPolicyData {
    id: number;
    password_expiry_time: number;
    purchase_order_expiry_time: number;
    qr_expiry_time: number;
    session_expiry_time: number;
}

interface ApiResponse {
    status: number;
    message: string;
    data: ExpiryPolicyData[];
}

const ExpiryPolicy: React.FC = () => {
    const [purchaseOrder, setPurchaseOrder] = useState<string>('');
    const [passwordExpiryDays, setPasswordExpiryDays] = useState<string>('');
    const [qrExpiryDays, setQrExpiryDays] = useState<string>('');
    const [sessionHours, setSessionHours] = useState<string>('');
    const [sessionMinutes, setSessionMinutes] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const userType = sessionStorage.getItem('userType') || '';

    // Convert minutes to hours and minutes
    const convertMinutesToHoursAndMinutes = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return { hours, minutes };
    };

    // Fetch initial data
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await fetchExpiryPolicy();
            const apiResponse = response.data as ApiResponse;

            if (response.status === 200 && apiResponse.data && apiResponse.data.length > 0) {
                const policyData = apiResponse.data[0];
                setPurchaseOrder(policyData.purchase_order_expiry_time.toString());
                setPasswordExpiryDays(policyData.password_expiry_time.toString());
                setQrExpiryDays(policyData.qr_expiry_time.toString());

                // Convert session time from minutes to hours and minutes
                const { hours, minutes } = convertMinutesToHoursAndMinutes(
                    policyData.session_expiry_time
                );
                setSessionHours(hours.toString());
                setSessionMinutes(minutes.toString());
            }
        } catch (error) {
            toast.error('Failed to fetch expiry policy data');
            console.error('Error fetching expiry policy:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSave = async () => {
        try {
            const purchaseOrderNum = Number(purchaseOrder);
            const passwordExpiryDaysNum = Number(passwordExpiryDays);
            const qrExpiryDaysNum = Number(qrExpiryDays);
            const sessionHoursNum = Number(sessionHours || 0);
            const sessionMinutesNum = Number(sessionMinutes || 0);

            // Validation
            if (
                isNaN(purchaseOrderNum) ||
                isNaN(passwordExpiryDaysNum) ||
                isNaN(qrExpiryDaysNum) ||
                isNaN(sessionHoursNum) ||
                isNaN(sessionMinutesNum)
            ) {
                toast.error('Please enter valid numbers for all fields');
                return;
            }

            const sessionTimeInMinutes = sessionHoursNum * 60 + sessionMinutesNum;

            const expiryData = {
                password_expiry_time: passwordExpiryDaysNum,
                purchase_order_expiry_time: purchaseOrderNum,
                qr_expiry_time: qrExpiryDaysNum,
                session_expiry_time: sessionTimeInMinutes,
            };

            setIsLoading(true);
            const response = await updateExpiryPolicy(expiryData);

            if (response.status === 200) {
                toast.success(response.data.message || 'Expiry policy updated successfully', {
                    onClose: () => {
                        fetchData();
                    },
                });
            } else {
                toast.error(response.data.message || 'Failed to update expiry policy');
            }
        } catch (error) {
            toast.error('An error occurred while updating expiry policy');
            console.error('Error updating expiry policy:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <h2 className="expiry-policy-title">Expiry Policy Settings
            </h2>
            {/* <BackButton redirectTo="/security-settings" /> */}
            <div className="expiry-policy-section">
                
                <label className="section-label">Purchase Order Expiry</label>
                <div className="expiry-policy-input-wrapper">
                    <input
                        type="number"
                        className="expiry-policy-input"
                        value={purchaseOrder}
                        onChange={(e) => setPurchaseOrder(e.target.value)}
                        placeholder="Enter Days"
                        min="0"
                        disabled={userType === 'vendor'}
                    />
                    <span className="expiry-policy-unit">Days</span>
                </div>
            </div>

            <div className="expiry-policy-section">
                <label className="section-label">Password Expiry</label>
                <div className="expiry-policy-input-wrapper">
                    <input
                        type="number"
                        className="expiry-policy-input"
                        value={passwordExpiryDays}
                        onChange={(e) => setPasswordExpiryDays(e.target.value)}
                        placeholder="Enter Days"
                        min="0"
                        disabled={userType === 'vendor'}
                    />
                    <span className="expiry-policy-unit">Days</span>
                </div>
            </div>

            <div className="expiry-policy-section">
                <label className="section-label">QR Codes Expiry</label>
                <div className="expiry-policy-input-wrapper">
                    <input
                        type="number"
                        className="expiry-policy-input"
                        value={qrExpiryDays}
                        onChange={(e) => setQrExpiryDays(e.target.value)}
                        placeholder="Enter Days"
                        min="0"
                        disabled={userType === 'vendor'}
                    />
                    <span className="expiry-policy-unit">Days</span>
                </div>
            </div>

            <div className="expiry-policy-section">
                <label className="section-label">Session Expiry</label>
                <div className="session-time-picker">
                    <div className="expiry-policy-input-wrapper">
                        <input
                            type="number"
                            className="expiry-policy-input"
                            value={sessionHours}
                            onChange={(e) => setSessionHours(e.target.value)}
                            placeholder="Hours"
                            min="0"
                            disabled={userType === 'vendor'}
                        />
                        <span className="expiry-policy-unit">Hours</span>
                    </div>
                    <div className="expiry-policy-input-wrapper">
                        <input
                            type="number"
                            className="expiry-policy-input"
                            value={sessionMinutes}
                            onChange={(e) => setSessionMinutes(e.target.value)}
                            placeholder="Minutes"
                            min="0"
                            max="59"
                            disabled={userType === 'vendor'}
                        />
                        <span className="expiry-policy-unit">Minutes</span>
                    </div>
                </div>
            </div>

            {userType !== 'vendor' && (
                <button
                    className="save-btn"
                    onClick={handleSave}
                    disabled={isLoading}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </button>
            )}
        </div>
    );
};

export default ExpiryPolicy;
