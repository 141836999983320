import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, TextField, InputAdornment, Select, MenuItem, FormControl } from '@mui/material';
import { Search as SearchIcon, RefreshCw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import Header from '../dashboard/components/Header';
import { useGeoContext } from '../../components/GeoContext';
import CameraCapture from './components/CameraCapture';
import './PurchaseOrder.css';
import { 
    fetchPurchaseOrders, 
    uploadPurchaseOrder, 
    generateQrPoLevel,
    downloadQrForAdmin,
    getUserList 
} from '../../utils/services/apiService';
import UploadResultPopup from './UploadResultPopup';
import QRCodeOverviewCards from './components/QRCodeOverviewCards';

interface DataItem {
    id: string;
    sr_no: number;
    purchase_order_number: string;
    no_of_line_items: string;
    purchase_order_date: string;
    vendor_name: string;
    plant: string;
    purchase_order_status: string;
    purchase_order_completion: string;
}

export interface ProcessedData {
    purchase_order_number: string;
    status: string;
    short_message: string;
    message?: string;
    created_by?: number;
    file_upload_id?: number;
}

export interface UnprocessedData {
    entry: {
        [key: string]: string;
    };
    reason: string;
}

interface VendorItem {
    user_id: number;
    user_full_name: string;
}

const useDebounce = (value: string, delay: number = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
};

const PurchaseOrder: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<DataItem[]>([]);
    const [filteredData, setFilteredData] = useState<DataItem[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const [selectedVendor, setSelectedVendor] = useState('all');
    const [loading, setLoading] = useState(true);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [processedData, setProcessedData] = useState<ProcessedData[]>([]);
    const [unprocessedData, setUnprocessedData] = useState<UnprocessedData[]>([]);
    const [downloadStatus, setDownloadStatus] = useState<string | null>(null);
    const [vendorList, setVendorList] = useState<VendorItem[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { permissionStatus } = useGeoContext();
    const typeOfUser = sessionStorage.getItem('userType');
    const [showCamera, setShowCamera] = useState(false);
const [currentPO, setCurrentPO] = useState('');

useEffect(() => {
    loadPurchaseOrders();
    if (typeOfUser === 'admin') {
        loadVendorList();
    }
}, []); // Initial load

useEffect(() => {
    loadPurchaseOrders();
}, [selectedVendor]);

    useEffect(() => {
        if (typeOfUser === 'admin' && vendorList.length > 0) {
            // Set the first vendor as default
            setSelectedVendor(vendorList[0].user_id.toString());
        }
    }, [vendorList]);

    useEffect(() => {
        filterData();
    }, [debouncedSearchTerm, selectedVendor, data]);

    const loadVendorList = async () => {
        try {
            const response = await getUserList();
            if (response.status === 200 && response.data?.data) {
                setVendorList(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching vendor list:', error);
        }
    };

    const handleVendorChange = (event: any) => {
        setSelectedVendor(event.target.value);
    };

    const handleRefresh = () => {
        loadPurchaseOrders();
        setSearchTerm('');
        if (typeOfUser === 'admin' && vendorList.length > 0) {
            setSelectedVendor(vendorList[0].user_id.toString());
        }
    };

    const loadPurchaseOrders = async () => {
        setLoading(true);
        try {
            // For admin, pass the selected vendor's user_id
            const requestData = typeOfUser === 'admin' && selectedVendor !== 'all'
                ? { user_id: parseInt(selectedVendor) }
                : {};
    
            const { data } = await fetchPurchaseOrders(requestData);
    
            if (data.status === 200 && data.data?.purchaseOrders) {
                const normalizedData = data.data?.purchaseOrders.map((item: any, index: number) => ({
                    sr_no: index + 1,
                    purchase_order_number: item?.purchase_order_number || "",
                    no_of_line_items: item.no_of_line_items || "",
                    purchase_order_date: item.purchase_order_date || "",
                    vendor_name: item.vendor.user_full_name || "",
                    plant: item.plant || "",
                    purchase_order_status: item.purchase_order_status || "",
                    purchase_order_completion: item.purchase_order_completion || "",
                    id: item.id
                }));
                setData(normalizedData);
                setFilteredData(normalizedData);    
            } else {
                console.error('Error fetching purchase orders:', data.statusText);
            }
        } catch (error) {
            console.error('API error:', error);
        } finally {
            setLoading(false);
        }
    };
    const checkCameraPermissions = async (): Promise<boolean> => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameras = devices.filter(device => device.kind === 'videoinput');
            
            // If no cameras are found
            if (cameras.length === 0) {
                toast.error('No camera detected on your device.', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                return false;
            }

            // Try to get camera permissions
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach(track => track.stop()); // Stop the stream immediately
            return true;
        } catch (error) {
            toast.error('Please enable camera access to proceed with download.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return false;
        }
    };
    const filterData = () => {
        let filtered = [...data];
    
        // Apply search filter
        if (debouncedSearchTerm.trim()) {
            const searchTermLower = debouncedSearchTerm.toLowerCase().trim();
            filtered = filtered.filter(item => 
                item.vendor_name.toLowerCase().includes(searchTermLower) ||
                item.purchase_order_number.toLowerCase().includes(searchTermLower) ||
                item.purchase_order_date.toLowerCase().includes(searchTermLower)
            );
        }
    
        // Update serial numbers
        const updatedFiltered = filtered.map((item, index) => ({
            ...item,
            sr_no: index + 1
        }));
        
        setFilteredData(updatedFiltered);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleViewDetails = (rowData: DataItem) => {
        navigate(`/purchase-order-details/${rowData.purchase_order_number}`);
    };

    const handleDownload = async (rowData: DataItem) => {
        if (permissionStatus !== 'granted') {
            toast.error('Please enable location services to Download.', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }
    
        if (rowData.purchase_order_status.toLowerCase() === 'completed') {
            try {
                const response = await downloadQrForAdmin(rowData.purchase_order_number);
                if (response.status === 200) {
                    toast.success('QR codes have been sent to your email.');
                } else {
                    toast.error('Error processing your request. Please try again later.');
                }
            } catch (error) {
                console.error('Download error:', error);
                toast.error('Error processing your request. Please try again later.');
            }
        } else {
            // Check camera permissions before opening camera
            const hasCameraPermission = await checkCameraPermissions();
            if (hasCameraPermission) {
                setCurrentPO(rowData.purchase_order_number);
                setShowCamera(true);
            }
        }
    };

    const handleImageCapture = async (imageBlob: Blob) => {
        try {
            const formData = new FormData();
            formData.append('purchaseOrderNumber', currentPO);
            formData.append('image_buffer', imageBlob, 'capture.jpg');
            
            const latitude = sessionStorage.getItem('latitude');
            const longitude = sessionStorage.getItem('longitude');
            if (latitude && longitude) {
                formData.append('latitude', latitude);
                formData.append('longitude', longitude);
            }
    
            const response = await generateQrPoLevel(formData);
            if (response.status === 200) {
                toast.success('QR codes generation request has been processed. You will receive an email shortly.');
            } else {
                toast.error('Error processing your request. Please try again later.');
            }
        } catch (error) {
            console.error('Error processing image:', error);
            toast.error('Error processing your request. Please try again later.');
        }
    };
    
    const handleCameraClose = () => {
        setShowCamera(false);
        setCurrentPO('');
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setUploadStatus(`Uploading ${file.name}...`);
            try {
                const response = await uploadPurchaseOrder(file);
                if (response.status === 200 && response.data.purchaseOrders) {
                    setUploadStatus(`${file.name} uploaded successfully`);
                    setProcessedData(response.data.purchaseOrders.processedData || []);
                    setUnprocessedData(response.data.purchaseOrders.unproccesedData || []);
                    setIsPopupOpen(true);
                    loadPurchaseOrders();
                } else {
                    setUploadStatus(`Error uploading ${file.name}`);
                }
            } catch (error) {
                console.error('Upload error:', error);
                setUploadStatus(`Error uploading ${file.name}`);
            } finally {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
            setTimeout(() => {
                setUploadStatus(null);
            }, 3000);
        } else {
            setUploadStatus(null);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const renderVendorDropdown = () => (
        <FormControl size="small" sx={{ 
            minWidth: 250, // Increased width
            '& .MuiSelect-select': {
                paddingRight: '40px !important', // Ensure enough space for dropdown icon
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }}>
            <Select
                value={selectedVendor}
                onChange={handleVendorChange}
                sx={{
                    borderRadius: '8px',
                    height: '40px',
                    '& .MuiSelect-icon': {
                        right: '10px' // Adjust icon position if needed
                    }
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 300 // Limit dropdown height if many vendors
                        }
                    }
                }}
            >
                {vendorList.map((vendor) => (
                    <MenuItem 
                        key={vendor.user_id} 
                        value={vendor.user_id.toString()}
                        sx={{ width: '100%' }}
                    >
                        {vendor.user_full_name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const columns: ColumnConfig[] = [
        {
            field: 'sr_no',
            headerName: 'Sr No.',
            width: 80,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'purchase_order_number',
            headerName: 'PO Number',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'no_of_line_items',
            headerName: 'No. of Line Items',
            width: 150,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'purchase_order_date',
            headerName: 'PO Date',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'vendor_name',
            headerName: 'Vendor Name',
            width: 280,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'purchase_order_status',
            headerName: 'PO Status',
            width: 140,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'purchase_order_completion',
            headerName: 'PO Completion Date',
            width: 170,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 250,
            renderHeader: (params) => <div className="table-header table-header-center">{params.colDef.headerName}</div>,
            renderCell: (params) => {
                const isVendor = typeOfUser === 'vendor';
                const isCompleted = params.row.purchase_order_status.toLowerCase() === 'completed';
                const showDownloaded = isVendor && isCompleted;
                const isGeoEnabled = permissionStatus === 'granted';

                return (
                    <div className="button-group">
                        <Button
                            variant="contained"
                            onClick={() => handleViewDetails(params.row as DataItem)}
                            className="button-rounded"
                        >
                            View details
                        </Button>
                        {showDownloaded ? (
                            <Button
                                variant="outlined"
                                className="button-rounded"
                                style={{
                                    cursor: '#666',
                                    backgroundColor: 'white',
                                    color: '#666',
                                    pointerEvents: 'none',
                                    borderColor: '#666',
                                }}
                            >
                                Downloaded
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                onClick={() => handleDownload(params.row as DataItem)}
                                className="button-rounded"
                                style={!isGeoEnabled ? {
                                    cursor: 'pointer',
                                    opacity: 0.6
                                } : {}}
                            >
                                Download
                            </Button>
                        )}
                    </div>
                );
            },
        },
        {
            field: 'view_po',
            headerName: 'View PO',
            width: 110,
            renderHeader: (params) => <div className="table-header">{params.colDef.headerName}</div>,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
    ];

    return (
        <div className="screen">
            <Header/>
            <ToastContainer  
                position="top-center"
                autoClose={4000} 
            />

            <div className="table">
                {/* <QRCodeOverviewCards /> */}
                <div className="table-header-container">
                    
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Typography variant="body1" className="breadcrumb">
                            Purchase Order
                        </Typography>
                        <TextField
                            size="small"
                            placeholder="Search by PO Number, Name, Date"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon size={20} />
                                    </InputAdornment>
                                ),
                                style: { borderRadius: '8px' }
                            }}
                            sx={{
                                width: '300px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                }
                            }}
                        />
                        {typeOfUser === 'admin' && renderVendorDropdown()}
                        <Button
                            variant="outlined"
                            onClick={handleRefresh}
                            sx={{
                                borderRadius: '8px',
                                height: '40px',
                                minWidth: '40px',
                                padding: '8px'
                            }}
                        >
                        <RefreshCw size={20} />
                        </Button>

                    </div>
                    {typeOfUser === 'admin' && (
                        <Button
                            variant="contained"
                            component="label"
                            className="upload-button"
                        >
                            Upload PO
                            <input
                                ref={fileInputRef}
                                type="file"
                                hidden
                                accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    )}
                </div>

                <QRCodeOverviewCards />
                {uploadStatus && (
                    <Typography variant="body2" className="upload-status">
                        {uploadStatus}
                    </Typography>
                )}
                {downloadStatus && (
                    <Typography variant="body2" className="download-status">
                        {downloadStatus}
                    </Typography>
                )}
                {showCamera && (
    <CameraCapture
        onImageCapture={handleImageCapture}
        onClose={handleCameraClose}
    />
)}
                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={filteredData}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.id}
                    />
                </div>
            </div>
            <UploadResultPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                processedData={processedData}
                unprocessedData={unprocessedData}
            />
        </div>
    );
};

export default PurchaseOrder;