import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import { getQrGenerationLogs } from '../../../utils/services/apiService'; 

interface GmProductSku {
    description: string;
    item_code: string;
}

interface PurchaseOrder {
    purchase_order_number: string;
}

interface QrGenerationLogItem {
    quantity: number;
    status: string;
    short_message: string;
    gmProductSku: GmProductSku;
    purchaseOrder: PurchaseOrder;
    user: {
        user_full_name: string;
        user_type: string;
    };
}

const QrGenerationLogs: React.FC = () => {
    const [data, setData] = useState<QrGenerationLogItem[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadQrGenerationLogData();
    }, []);

    const loadQrGenerationLogData = async () => {
        try {
            setLoading(true);
            const response = await getQrGenerationLogs();
            if (response.status === 200) {
                setData(response.data);
            } else {
                console.error('Failed to fetch QR generation logs:', response);
                // Handle error appropriately
            }
        } catch (error) {
            console.error('Error fetching QR generation logs:', error);
            // Handle error appropriately
        } finally {
            setLoading(false);
        }
    };

    // Transform the data to match the table structure
    const transformedData = data.map((item, index) => ({
        id: index.toString(),
        quantity: item.quantity,
        status: item.status,
        short_message: item.short_message,
        description: item.gmProductSku?.description || '',
        item_code: item.gmProductSku?.item_code || '',
        purchase_order_number: item.purchaseOrder?.purchase_order_number || '',
        user: item.user?.user_full_name || 'N/A',
        user_type: item.user?.user_type || 'N/A'
    }));

    const columns: ColumnConfig[] = [
        // { 
        //     field: 'vendor_name', 
        //     headerName: 'Vendor Name', 
        //     width: 120 
        // },
        { 
            field: 'purchase_order_number', 
            headerName: 'PO Number', 
            width: 110 
        },
        { 
            field: 'item_code', 
            headerName: 'Item Code', 
            width: 170 
        },
        { 
            field: 'description', 
            headerName: 'SKU Description', 
            width: 280 
        },
        { 
            field: 'quantity', 
            headerName: 'Quantity', 
            width: 90 
        },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 100,
            renderCell: (params: any) => (
                <Typography
                    style={{
                        textTransform: 'capitalize',
                        marginTop: '2vh',
                    }}
                >
                    {params.value}
                </Typography>
            )
        },
        { 
            field: 'short_message', 
            headerName: 'Message', 
            width: 600 
        },
        { 
            field: 'user', 
            headerName: 'User Name', 
            width: 200 
        },
        { 
            field: 'user_type', 
            headerName: 'User Type', 
            width: 120 
        },
        // { 
        //     field: 'geo_location', 
        //     headerName: 'Geo Location', 
        //     width: 120 
        // },
        // { 
        //     field: 'ip_address', 
        //     headerName: 'IP Address',  
        //     width: 120 
        // },
        // { 
        //     field: 'view_image', 
        //     headerName: 'Image', 
        //     width: 110 
        // },
        // { 
        //     field: 'date', 
        //     headerName: 'Date', 
        //     width: 110 
        // }
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between', padding: '0px' }}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            QR Generation Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={transformedData}
                        columns={columns}
                        loading={loading}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default QrGenerationLogs;