import React, { useState, useEffect } from 'react';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { getQrCodeOverview } from '../../../utils/services/apiService';
import './QRCodeOverviewCards.css';

interface QrCodeOverview {
    totalQrsRequested: number;
    totalQrsGenerated: number;
    totalQrsPendingForGeneration: number;
    totalQrsScanned: number;
}

// Custom function to format numbers in the Indian numbering system
const formatNumberIndian = (number: number) => {
    const numStr = number.toString();
    const [integerPart, decimalPart] = numStr.split(".");
    const lastThree = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    const formattedInteger = otherDigits
        .replace(/\B(?=(\d{2})+(?!\d))/g, ",")
        .concat(otherDigits ? "," : "", lastThree);

    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const QRCodeOverviewCards: React.FC = () => {
    const [qrCodeOverview, setQrCodeOverview] = useState<QrCodeOverview>({
        totalQrsRequested: 0,
        totalQrsGenerated: 0,
        totalQrsPendingForGeneration: 0,
        totalQrsScanned: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchQrCodeOverview();
    }, []);

    const fetchQrCodeOverview = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await getQrCodeOverview();

            if (response && response.data && response.status === 200) {
                setQrCodeOverview({
                    totalQrsRequested: Number(response.data.data.totalQrsRequested),
                    totalQrsGenerated: Number(response.data.data.totalQrsGenerated),
                    totalQrsPendingForGeneration: Number(response.data.data.totalQrsPendingForGeneration),
                    totalQrsScanned: Number(response.data.data.totalQrsScanned || 0)
                });
            } else {
                setError('Failed to fetch QR code overview data');
            }
        } catch (err) {
            setError('An error occurred while fetching QR code overview data');
            console.error('Error fetching QR code overview:', err);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="gridBox">
            <div className="pointsRow">
                <div className="greyContainer containerColor2">
                    <div className="dashboardIcon2">
                        <ThreePOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsRequested)}</h2>
                        <span>Total QR Codes Available</span>
                    </div>
                </div>
                <div className="greyContainer containerColor1">
                    <div className="dashboardIcon">
                        <DocumentScannerOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsGenerated)}</h2>
                        <span>QR Codes Generated</span>
                    </div>
                </div>
                <div className="greyContainer containerColor3">
                    <div className="dashboardIcon3">
                        <FileDownloadOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsPendingForGeneration)}</h2>
                        <span>QR Codes Pending For Generation</span>
                    </div>
                </div>
                <div className="greyContainer containerColor4">
                    <div className="dashboardIcon4">
                        <QrCodeScannerIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsScanned)}</h2>
                        <span>Additional QR Codes</span>
                    </div>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default QRCodeOverviewCards;
