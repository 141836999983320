import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, Box } from '@mui/material';
interface ForbiddenGeoLocationProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const ForbiddenGeoLocation: React.FC<ForbiddenGeoLocationProps> = ({
  isOpen = false,
  onClose,
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      setTimeout(() => {
        setOpen(false);
        onClose && onClose();
      }, 6000);
    }
  }, [isOpen, onClose]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000} 
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity="error"
        sx={{
          width: '100%',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        Please enable location access in your browser settings to use this website.
      </Alert>
    </Snackbar>
  );
};

export default ForbiddenGeoLocation;
