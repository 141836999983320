import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Container, Divider, Paper } from '@mui/material';
import Header from '../dashboard/components/Header';
import './PasswordPolicies.css';
import BackButton from '../../components/BackButton';

const PasswordPolicies: React.FC = () => {
    const policies = [
        "Passwords must be at least 8 characters long.",
        "Passwords must include at least one lowercase letter, one uppercase letter, one number, and one special character (e.g., !, @, #, $, etc.).",
        "Avoid using easily guessable information such as your name, username, or common words like 'password.'",
        "Passwords must be changed at least every 90 days.",
        "Do not reuse passwords from previous accounts. Each password should be unique to your account.",
        "It is strongly recommended to enable multi-factor authentication for an extra layer of security.",
        "After 5 failed login attempts, your account will be temporarily locked for 15 minutes to prevent unauthorized access.",
        "Do not store passwords in plain text or insecure locations (e.g., notebooks, text files).",
    ];

    return (
        <Container maxWidth="md" className="password-policies-container">
            <Header />
            <div>
                
            </div>
            <Box py={4}>
                <Box display="flex" justifyContent="flex-start" alignItems="left" marginRight="100">
                    
                </Box>
                <BackButton redirectTo="/security-settings" />
                <Typography variant="h4" className="title" gutterBottom >
          
                    Password Policies
                </Typography>
                <Typography variant="subtitle1" className="description" gutterBottom>
                    To ensure the security of your account, please adhere to the following password guidelines:
                </Typography>
                <Divider className="divider" />
                <Paper elevation={2} className="policies-section">
                    <List>
                        {policies.map((policy, index) => (
                            <ListItem key={index} className="policy-item">
                                <ListItemText
                                    primary={`${index + 1}. ${policy}`}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        className: 'policy-text',
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
                <Divider className="divider" />
                <Typography variant="body2" className="footer-text">
                    Please ensure your password meets these guidelines to maintain the security of your account.
                    If you have any issues with password changes or security concerns, please contact our support team.
                </Typography>
            </Box>
        </Container>
    );
};

export default PasswordPolicies;