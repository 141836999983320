import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
    Typography, 
    Button, 
    MenuItem, 
    Select, 
    InputAdornment, 
    Grid,
    TextField,
    Menu,
    IconButton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomTable, { ColumnConfig } from "../../components/CustomTable";
import Header from '../dashboard/components/Header';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { fetchUsers, updateUserStatus } from '../../utils/services/apiService';
import './UserManagement.css';

interface UserDetails {
    user_id: number;
    user_full_name: string;
    user_email: string;
    user_mobile_number: string;
    user_type: string;
    status: string;
    user_code: string | null;
    binding_ip: string;
    created_at: string;
    updated_at: string;
}

interface ApiUserData {
    user_id: number;
    user_full_name: string;
    user_email: string;
    user_mobile_number: string;
    user_type: string;
    user_code: string | null;
    binding_ip: string;
    created_at: string;
    updated_at: string;
    status: 'active' | 'inactive';
    created_by: UserDetails;
    updated_by: UserDetails;
    gstin: string | null;
    pan: string | null;
    address: string | null;
}

interface UserData {
    id: string;
    sr_no: number;
    full_name: string;
    user_id: string;
    email: string;
    mobile: string;
    role: string;
    ip_binding: string;
    status: 'active' | 'inactive';
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    anchorEl?: HTMLElement | null;
    gstin: string;
    pan: string;
    address: string;
}



const UserManagement: React.FC = () => {
    const navigate = useNavigate();
    const [originalData, setOriginalData] = useState<UserData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [userTypeFilter, setUserTypeFilter] = useState('vendor');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const loadUsers = async () => {
        try {
            setLoading(true);
            setError(null);
    
            const response = await fetchUsers();
    
            if (response.status === 200 && response.data.status === 200) {
                const transformedData: UserData[] = response.data.data.map((user: ApiUserData, index: number) => ({
                    id: user.user_id.toString(),
                    sr_no: index + 1,
                    full_name: user.user_full_name,
                    user_id: user.user_code || '-',
                    email: user.user_email,
                    mobile: user.user_mobile_number,
                    role: user.user_type.charAt(0).toUpperCase() + user.user_type.slice(1),
                    ip_binding: user.binding_ip,
                    status: user.status || 'inactive',
                    created_at: user.created_at,
                    updated_at: user.updated_at,
                    created_by: user.created_by?.user_full_name || '-',
                updated_by: user.updated_by?.user_full_name || '-',
                gstin: user.gstin || '-',
                    pan: user.pan || '-',
                    address: user.address || '-',
                    anchorEl: null
                }));
    
                setOriginalData(transformedData);
            } else {
                setError(response.data.message || 'Failed to fetch users. Please try again later.');
            }
        } catch (err) {
            console.error('Error fetching users:', err);
            setError('An error occurred while fetching users. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const handleMenuOpen = useCallback((userId: string, event: React.MouseEvent<HTMLButtonElement>) => {
        setOriginalData(prevData => 
            prevData.map(user => 
                user.id === userId 
                    ? { ...user, anchorEl: event.currentTarget } 
                    : { ...user, anchorEl: null }
            )
        );
    }, []);

    const handleMenuClose = useCallback((userId: string) => {
        setOriginalData(prevData => 
            prevData.map(user => 
                user.id === userId 
                    ? { ...user, anchorEl: null } 
                    : user
            )
        );
    }, []);

    const handleEditUser = useCallback((userId: string) => {
        // Find the user data before navigating
        const userData = originalData.find(user => user.id === userId);
        if (userData) {
            // Navigate with state containing the user data
            navigate(`/create-user?edit=${userId}`, {
                state: {
                    editMode: true,
                    userData: {
                        userFullName: userData.full_name,
                        userEmail: userData.email,
                        userCode: userData.user_id,
                        bindingIp: userData.ip_binding,
                        userType: userData.role.toLowerCase(),
                        userMobile: userData.mobile
                    }
                }
            });
        }
        handleMenuClose(userId);
    }, [navigate, originalData, handleMenuClose]);

    const handleUpdateStatus = useCallback(async (userId: string, newStatus: 'active' | 'inactive') => {
        // Show loading toast
        const toastId = toast.loading('Updating user status...', {
            position: "top-center"
        });
        
        try {
            const response = await updateUserStatus({
                user_id: parseInt(userId),
                user_status: newStatus
            });

            if (response.status === 200 && response.data.status === 200) {
                // Update the local state to reflect the change
                setOriginalData(prevData =>
                    prevData.map(user =>
                        user.id === userId
                            ? { ...user, status: newStatus }
                            : user
                    )
                );

                // Update loading toast to success
                toast.update(toastId, {
                    render: `User successfully ${newStatus === 'active' ? 'activated' : 'deactivated'}`,
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                throw new Error(response.data.message || 'Failed to update user status');
            }
        } catch (err) {
            console.error('Error updating user status:', err);
            // Update loading toast to error
            toast.update(toastId, {
                render: 'Failed to update user status. Please try again.',
                type: "error",
                isLoading: false,
                autoClose: 3000
            });
        } finally {
            handleMenuClose(userId);
        }
    }, []);

   

    const filteredData = useMemo(() => {
        let result = originalData;

        if (searchTerm) {
            const searchTermLower = searchTerm.toLowerCase();
            result = result.filter(user => 
                user.full_name.toLowerCase().includes(searchTermLower) ||
                user.email.toLowerCase().includes(searchTermLower)
            );
        }

        if (userTypeFilter) {
            result = result.filter(user => 
                user.role.toLowerCase() === userTypeFilter.toLowerCase()
            );
        }

        return result;
    }, [originalData, searchTerm, userTypeFilter]);

    const columns: ColumnConfig[] = [
        {
            field: 'sr_no',
            headerName: 'Sr No.',
            width: 60,
            renderCell: (params) => <div className="user-management-cell-left">{params.value}</div>
        },
        {
            field: 'full_name',
            headerName: 'Name',
            width: 280,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 260,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            width: 100,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'user_id',
            headerName: 'Vendor Code',
            width: 110,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'role',
            headerName: 'User Type',
            width: 90,
            renderCell: (params) => (
                <div className={`cell-center ${params.value.toLowerCase()}-role`}>
                    {params.value}
                </div>
            )
        },
        {
            field: 'ip_binding',
            headerName: 'IP Binding',
            width: 110,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: (params) => (
                <div className="cell-center">{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</div>
            )
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 100,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 220,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'updated_by',
            headerName: 'Updated By',
            width: 100,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'updated_at',
            headerName: 'Updated Date',
            width: 220,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'pan',
            headerName: 'PAN',
            width: 140,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'gstin',
            headerName: 'GSTIN',
            width: 180,
            renderCell: (params) => <div className="cell-center">{params.value}</div>
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 280,
            renderCell: (params) => <div className="cell-left">{params.value}</div>
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => {
                const user = params.row;
                return (
                    <>
                        <IconButton 
                            onClick={(e) => handleMenuOpen(user.id, e)}
                            size="small"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={user.anchorEl}
                            open={Boolean(user.anchorEl)}
                            onClose={() => handleMenuClose(user.id)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                sx: {
                                    width: '150px'
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleEditUser(user.id)}>
                                Edit
                            </MenuItem>
                            {user.status === 'inactive' && (
                                <MenuItem onClick={() => handleUpdateStatus(user.id, 'active')}>
                                    Activate
                                </MenuItem>
                            )}
                            {user.status === 'active' && (
                                <MenuItem onClick={() => handleUpdateStatus(user.id, 'inactive')}>
                                    Deactivate
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                );
            }
        }
    ];

    return (
        <div className="user-management-screen">
            <Header />
            <ToastContainer  
                position="top-center"
                autoClose={4000} 
            />
            <div className="user-management-table">
                <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                    <Grid item>
                        <Typography variant="body1" className="user-management-breadcrumb">
                            User Management
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            sx={{ width: 200 }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Select
                            value={userTypeFilter}
                            onChange={(e) => setUserTypeFilter(e.target.value as string)}
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="vendor">Vendor</MenuItem>
                            <MenuItem value="analytics">Analytics</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/create-user')}
                            size="large"
                            sx={{
                                backgroundColor: '#EC1B3B',
                                color: 'white',
                                borderRadius: '10px',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#EC1B3B'
                                }
                            }}
                            aria-label="Create new user"
                        >
                            <PlusRoundIcon style={{ marginRight: '10px' }} />
                            Create User
                        </Button>
                    </Grid>
                </Grid>

                {error && (
                    <Typography color="error" sx={{ mt: 2, mb: 2 }}>
                        {error}
                    </Typography>
                )}

                <div className="user-management-custom-table">
                    <CustomTable
                        editMode="row"
                        data={filteredData}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.id}
                    />
                </div>
            </div>
            
        </div>

        
    );
};

export default UserManagement;