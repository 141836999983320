import React, { useState } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import excelIcon from '../../../assets/excel.png';

interface QRWiseData {
    qrCode: string;
    dateGenerated: string;
    scannedStatus: string;
    vendorName: string;
}

const QRWiseReport: React.FC = () => {
    const [data, setData] = useState<QRWiseData[]>([
        {
            qrCode: "QR123456",
            dateGenerated: "2024-11-01",
            scannedStatus: "Scanned",
            vendorName: "Vendor A",
        },
        {
            qrCode: "QR654321",
            dateGenerated: "2024-11-05",
            scannedStatus: "Not Scanned",
            vendorName: "Vendor B",
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
    const [vendorName, setVendorName] = useState("");
    const userType = sessionStorage.getItem('userType');

    const columns: ColumnConfig[] = [
        { field: 'qrCode', headerName: 'QR Code', width: 180 },
        { field: 'dateGenerated', headerName: 'Date Generated', width: 180 },
        { field: 'scannedStatus', headerName: 'Scanned Status', width: 180 },
        { field: 'vendorName', headerName: 'Vendor Name', width: 180 },
    ];

    const handleFilterDialogOpen = () => setFilterDialogOpen(true);
    const handleFilterDialogClose = () => setFilterDialogOpen(false);

    const handleApplyFilter = () => {
        handleFilterDialogClose();
    };

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <BackButton redirectTo="/reports" />
                        <Typography variant="h6" className="breadcrumb">
                            QR Wise Report
                        </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterDialogOpen}
                            className="filter-button"
                        >
                            <FilterAltIcon style={{ color: 'white' }} />
                            Filter
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'whitesmoke', color: '#007d3c', fontWeight: 'bold', border: '1px solid #ccc' }}
                            className="export-button"
                        >
                            <img src={excelIcon} alt="Excel Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                            Export
                        </Button>
                    </Box>
                </div>
                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.qrCode}
                    />
                </div>

                {/* Filter Dialog */}
                <Dialog open={isFilterDialogOpen} onClose={handleFilterDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Filter QR Wise Report</DialogTitle>
                    <DialogContent>
                        {(userType === 'admin' || userType === 'analytics') && (
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel>Vendor Name</InputLabel>
                                <Select
                                    value={vendorName}
                                    onChange={(e) => setVendorName(e.target.value)}
                                    label="Vendor Name"
                                >
                                    <MenuItem value="">All Vendors</MenuItem>
                                    <MenuItem value="Vendor A">Vendor A</MenuItem>
                                    <MenuItem value="Vendor B">Vendor B</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleApplyFilter} color="primary" variant="contained">
                            Apply Filter
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default QRWiseReport;