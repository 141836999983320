import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import { fetchCommunicationLogs } from '../../../utils/services/apiService';

interface CommunicationLogResponse {
    id: number;
    communication_mode: string;
    purpose: string;
    short_message: string;
    user: {
        user_full_name: string;
    };
}

const CommunicationLogs: React.FC = () => {
    const [data, setData] = useState<CommunicationLogResponse[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            try {
                const response = await fetchCommunicationLogs();
                setData(response.data);
            } catch (error) {
                console.error('Error fetching logs:', error);
                // Handle error appropriately
            } finally {
                setLoading(false);
            }
        };

        fetchLogs();
    }, []);

    const columns: ColumnConfig[] = [
        { 
            field: 'communication_mode', 
            headerName: 'Communication Mode',
            width: 170,
            renderCell: (params: any) => (
                <span>
                    {params.row?.communication_mode || '-'}
                </span>
            )
        },
        { 
            field: 'purpose', 
            headerName: 'Purpose',
            width: 130,
            renderCell: (params: any) => (
                <span>
                    {params.row?.purpose || '-'}
                </span>
            )
        },
        { 
            field: 'short_message', 
            headerName: 'Message',
            width: 470,
            renderCell: (params: any) => (
                <span>
                    {params.row?.short_message || '-'}
                </span>
            )
        },
        { 
            field: 'user_full_name', 
            headerName: 'Name',
            width: 280,
            renderCell: (params: any) => (
                <span>
                    {params.row?.user?.user_full_name || '-'}
                </span>
            )
        },
        { 
            field: 'date', 
            headerName: 'Date',
            width: 120,
            renderCell: (params: any) => (
                <span>
                   
                </span>
            )
        }
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container flex justify-between items-center">
                    <Box className="flex items-center gap-2">
                        <BackButton redirectTo="/logs" />
                        <Typography variant="h6" className="breadcrumb">
                            Communication Logs
                        </Typography>
                    </Box>
                </div>

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(row: any) => row.id}
                    />
                </div>
            </div>
        </div>
    );
};

export default CommunicationLogs;