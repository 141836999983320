import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart, Area, CartesianGrid } from 'recharts';
import { 
    getQrCodeOverview,
    getSkuWiseQrCodesGenerated,
    getItemCodes,
    getTotalNumberOfQrsGeneratedYearWise 
} from '../../utils/services/apiService';
import QRCodeCountChart from './components/QR-Code-Count-Chart';
import SKUsStatusChart from './components/SKU-Status-Line-Graph';
import OldQRCodeSummary from './components/Old-QR-Code-Summary';
import PurchaseOrdersChart from './components/Purchase-Orders-Chart';
import SalesOrdersBarChart from './components/Purchase-Orders-Bar-Chart';
import VendorwisePurchaseOrderSummary from './components/Vendorwise-Purchase-Order';
import GRNDetailsBarChart from './components/GRN-Details';
import GRNGaugeChart from './components/GRN-GaugChart';
import QRCodeQCOverview from './components/QR-Code-QC-Overview';
import QRCodeGraph from './components/QR-Code-Graph';
import Header from './components/Header';
import qrIcon from '../../assets/icons/skus_icon.png';
import './Dashboard.css';

interface QrCodeOverview {
    totalQrsRequested: number;
    totalQrsGenerated: number;
    totalQrsPendingForGeneration: number;
    totalQrsScanned: number;
}

interface SkuWiseData {
    name: string;
    Total: number;
}

interface LineGraphDataItem {
    month: string;
    value: number;
}

const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const Dashboard: React.FC = () => {
    const [skuYear, setSkuYear] = useState<number>(2024);
    const [totalQrYear, setTotalQrYear] = useState<number>(2024);
    const [selectedItemCode, setSelectedItemCode] = useState<string>('');
    const [chartData, setChartData] = useState<SkuWiseData[]>([]);
    const [lineGraphData, setLineGraphData] = useState<LineGraphDataItem[]>([]);
    const [qrCodeOverview, setQrCodeOverview] = useState<QrCodeOverview>({
        totalQrsRequested: 0,
        totalQrsGenerated: 0,
        totalQrsPendingForGeneration: 0,
        totalQrsScanned: 0
    });
    const [userType, setUserType] = useState<string>('vendor');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [itemCodes, setItemCodes] = useState<string[]>([]);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
    const [chartError, setChartError] = useState<string | null>(null);

    useEffect(() => {
        const storedUserType = sessionStorage.getItem('userType');
        if (storedUserType) {
            setUserType(storedUserType);
        }
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        try {
            await Promise.all([
                fetchItemCodes(),
                fetchQrCodeOverview()
            ]);
        } catch (err) {
            console.error('Error fetching initial data:', err);
            setError('Failed to load initial dashboard data');
        }
    };

    const fetchItemCodes = async () => {
        try {
            const response = await getItemCodes();
            if (response && response.status === 200 && response.data?.data) {
                const codes = response.data.data || [];
                setItemCodes(codes);
                if (codes.length > 0) {
                    setSelectedItemCode(codes[0]);
                }
            } else {
                console.warn('No item codes found or invalid response');
                setItemCodes([]);
            }
        } catch (err) {
            console.error('Error fetching item codes:', err);
            setError('Failed to fetch item codes');
            setItemCodes([]);
        }
    };

    const fetchQrCodeOverview = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const response = await getQrCodeOverview();
            
            if (response && response.data && response.status === 200) {
                setQrCodeOverview({
                    totalQrsRequested: Number(response.data.data.totalQrsRequested),
                    totalQrsGenerated: Number(response.data.data.totalQrsGenerated),
                    totalQrsPendingForGeneration: Number(response.data.data.totalQrsPendingForGeneration),
                    totalQrsScanned: Number(response.data.data.totalQrsScanned || 0)
                });
            } else {
                setError('Failed to fetch QR code overview data');
            }
        } catch (err) {
            setError('An error occurred while fetching QR code overview data');
            console.error('Error fetching QR code overview:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSkuWiseData = async (itemCode: string, year: string) => {
        setIsLoadingChart(true);
        setChartError(null);
        try {
            const response = await getSkuWiseQrCodesGenerated(itemCode, year);
            if (response && response.status === 200) {
                // Initialize data for all months
                const initialData = monthNames.map(month => ({
                    name: month,
                    Total: 0
                }));

                // Update with actual data if available
                if (response.data && response.data.data && response.data.data.skuWiseQrsGenerated) {
                    response.data.data.skuWiseQrsGenerated.forEach((item: any) => {
                        const monthIndex = item.month - 1;
                        if (monthIndex >= 0 && monthIndex < 12) {
                            initialData[monthIndex].Total = item.totalQuantity || 0;
                        }
                    });
                }

                setChartData(initialData);
            } else {
                setChartError('Failed to fetch SKU-wise data');
            }
        } catch (err) {
            setChartError('Error loading SKU-wise data');
            console.error('Error fetching SKU-wise data:', err);
        } finally {
            setIsLoadingChart(false);
        }
    };
    const formatNumberIndian = (number) => {
        const numStr = number.toString();
        const [integerPart, decimalPart] = numStr.split(".");
        const lastThree = integerPart.slice(-3);
        const otherDigits = integerPart.slice(0, -3);
    
        const formattedInteger = otherDigits
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",")
            .concat(otherDigits ? "," : "", lastThree);
    
        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    };
    


    const fetchYearWiseData = async (year: number) => {
        try {
            const response = await getTotalNumberOfQrsGeneratedYearWise(year);
            if (response && response.status === 200) {
                // Initialize data for all months with zeros
                const initialData = monthNames.map(month => ({
                    month,
                    value: 0
                }));

                // Update only the data for the selected year
                if (response.data?.data?.totalQrsGeneratedYearWise) {
                    response.data.data.totalQrsGeneratedYearWise.forEach((item: any) => {
                        if (item.year === year) {
                            const monthIndex = item.month - 1;
                            if (monthIndex >= 0 && monthIndex < 12) {
                                initialData[monthIndex].value = item.totalQuantity;
                            }
                        }
                    });
                }

                setLineGraphData(initialData);
            }
        } catch (err) {
            console.error('Error fetching year-wise data:', err);
        }
    };

    useEffect(() => {
        if (selectedItemCode) {
            fetchSkuWiseData(selectedItemCode, skuYear.toString());
        }
    }, [selectedItemCode, skuYear]);

    useEffect(() => {
        fetchYearWiseData(totalQrYear);
    }, [totalQrYear]);

    const UserStatsGrid = () => (
        <div className="gridBox">
            <div className="pointsRow">
                <div className="greyContainer containerColor2">
                    <div className="dashboardIcon2">
                        <ThreePOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsRequested)}</h2>
                        <span>Total QR Codes Available</span>
                        {/* <div className='change'>↑ 24% From Yesterday</div> */}
                    </div>
                </div>
                <div className="greyContainer containerColor1">
                    <div className="dashboardIcon">
                        <DocumentScannerOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsGenerated)}</h2>
                        <span>QR Codes Generated</span>
                        {/* <div className='change'>↓ 24% From Yesterday</div> */}
                    </div>
                </div>
                <div className="greyContainer containerColor3">
                    <div className="dashboardIcon3">
                        <FileDownloadOutlinedIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsPendingForGeneration)}</h2>
                        <span>QR Codes Pending For Generation</span>
                        {/* <div className='change'>↑ 24% From Yesterday</div> */}
                    </div>
                </div>
                <div className="greyContainer containerColor4">
                    <div className="dashboardIcon4">
                        <QrCodeScannerIcon fontSize="medium" />
                    </div>
                    <div className="flexBox1">
                        <h2>{formatNumberIndian(qrCodeOverview.totalQrsScanned)}</h2>
                        <span>Additional QR Codes</span>
                    </div>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
    

    const QRCodeChart = () => (
        <div className="qr-code-chart">
            <div className="chart-header">
                <div>
                    <h2 className="chart-title">
                        <img 
                            src={qrIcon} 
                            alt="SKU Icon" 
                            style={{ width: '24px', height: '24px', verticalAlign: 'middle', marginRight: '8px' }} 
                        />
                        SKUs wise QR Code Generated
                    </h2>
                    <p className="chart-subtitle">For the year of {skuYear}</p>
                </div>
                <div className="chart-controls">
                    <FormControl sx={{ width: '85px', marginRight: '10px' }}>
                        <InputLabel>Year</InputLabel>
                        <Select
                            value={skuYear}
                            label="Year"
                            onChange={(e) => setSkuYear(e.target.value as number)}
                        >
                            {[2022, 2023, 2024].map((year) => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: '200px' }}>
                        <InputLabel>SKU Code</InputLabel>
                        <Select
                            value={selectedItemCode}
                            label="Item Code"
                            onChange={(e) => setSelectedItemCode(e.target.value as string)}
                        >
                            {itemCodes.map((code) => (
                                <MenuItem key={code} value={code}>{code}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
    
            {/* Removed Loading Effect */}
            {chartError && <div className="error-message">{chartError}</div>}
    
            {!chartError && (
                <>
                    <div className="chart-metrics">
                        <h1 className="metric-value">
                            {chartData.reduce((sum, item) => sum + item.Total, 0)}
                        </h1>
                        <p className="metric-description">QR codes have been generated</p>
                    </div>
    
                    <div className="chart-legend">
                        <div className="legend-item">
                            <div className="legend-color total"></div>
                            <span>Total QR Codes</span>
                        </div>
                    </div>
    
                    <ResponsiveContainer width="78%" height={240}>
                        <BarChart data={chartData}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="Total" fill="#00C4F7" />
                        </BarChart>
                    </ResponsiveContainer>
                </>
            )}
        </div>
    );
    

    const QRCodeLineGraph = () => (
        <div className="container bg-white p-4 rounded-lg shadow-lg max-w-3xl mx-auto mt-8">
            <div className="header flex justify-between items-center mb-4">
                <div className="flex items-center">
                    <div className="icon-container bg-blue-100 p-1.5 rounded mr-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3H10V10H3V3Z" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14 3H21V10H14V3Z" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3 14H10V21H3V14Z" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14 14H21V21H14V14Z" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <h2 className="text-lg font-semibold">Total Number of QR Codes Generated</h2>
                </div>
                <FormControl sx={{ width: '85px', marginTop: '20px' }}>
                    <InputLabel>Year</InputLabel>
                    <Select
                        value={totalQrYear}
                        label="Year"
                        onChange={(e) => setTotalQrYear(Number(e.target.value))}
                    >
                        {[2022, 2023, 2024].map((year) => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="mb-4">
            <div className="text-4xl font-bold">
                {lineGraphData.reduce((sum, item) => sum + item.value, 0)}
            </div>
            <div className="text-gray-500">QR Codes have been generated</div>
            {/* <p className="text-green-500 text-sm">+35% since past year</p> */}
        </div>

        <div style={{ width: '100%', height: 360 }}>
            <ResponsiveContainer>
                <AreaChart
                    data={lineGraphData}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#2563EB" fill="#DBEAFE" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    </div>
);

const matchesSearch = (sectionTitle: string): boolean => {
    if (!searchQuery) return true;
    return sectionTitle.toLowerCase().includes(searchQuery.toLowerCase());
};

// Define section titles for searching
const sections = {
    qrOverview: "QR Code Overview",
    skuWise: "SKUs wise QR Code Generated",
    totalQr: "Total Number of QR Codes Generated",
    qrCount: "Vendorwise QR Code Count",
    skuStatus: "SKUs with Active and Inactive Status",
    oldQr: "QR Code Summary Report",
    purchaseOrder: "Purchase Order Overview",
    grnOverview: "GRN Overview",
    qcOverview: "QR Code Quality Control Overview"
};
return (
    <div className="dashboard-container">
        <Header />
        <div className="dashboard-content">
            {/* QR Code Overview Section */}
            <div className='qrCodeOverview' style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <h3 style={{ margin: 0 }}>QR Code Overview</h3>
                <TextField
                    size="small"
                    placeholder="Search Dashboard..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        width: '300px',
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                            '& fieldset': {
                                borderColor: 'black',
                            },
                            '&:hover fieldset': {
                                borderColor: '#B2B7BE',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#2196F3',
                            },
                        },
                    }}
                />
            </div>

            {matchesSearch(sections.qrOverview) && <UserStatsGrid />}

            {/* SKUs wise QR Code Generated Section */}
            {matchesSearch(sections.skuWise) && <QRCodeChart />}

            {/* Total Number of QR Codes Generated and QR Code Count Section */}
            {(matchesSearch(sections.totalQr) || matchesSearch(sections.qrCount)) && (
                <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                    <div style={{ flex: 1 }}>
                        <QRCodeLineGraph />
                    </div>
                    <div style={{ flex: 1, marginTop: '10px' }}>
                        <QRCodeCountChart />
                    </div>
                </div>
            )}

            {/* Admin/Analytics Specific Section */}
            {(userType === 'admin' || userType === 'analytics') && (matchesSearch(sections.skuStatus) || matchesSearch(sections.oldQr)) && (
                <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
                    <div style={{ flex: 1 }}>
                        <SKUsStatusChart />
                    </div>
                    <div style={{ flex: 1 }}>
                        <OldQRCodeSummary />
                    </div>
                </div>
            )}

            {/* Purchase Order Overview Section */}
            {matchesSearch(sections.purchaseOrder) && (
                <>
                    <div style={{ marginTop: '20px' }}>
                        <Divider style={{ margin: '10px 0' }} />
                        <div className='qrCodeOverview' style={{ marginBottom: '0px' }}>
                            <h3 className="section-heading" style={{ margin: 0 }}>Purchase Order Overview</h3>
                        </div>
                    </div>
                    
                    <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <div style={{ flex: 1 }}>
                            <PurchaseOrdersChart />
                        </div>
                        <div style={{ flex: 1, maxWidth: '800px', flexGrow: 2 }}>
                            <SalesOrdersBarChart />
                        </div>
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <VendorwisePurchaseOrderSummary />
                    </div>
                </>
            )}

            {/* GRN Overview Section */}
            {matchesSearch(sections.grnOverview) && (
                <>
                    <div style={{ marginTop: '20px' }}>
                        <Divider style={{ margin: '10px 0' }} />
                        <div className='qrCodeOverview' style={{ marginBottom: '0px' }}>
                            <h3 className="section-heading" style={{ margin: 0 }}>GRN Overview</h3>
                        </div>
                    </div>
                    
                    <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
                        <div style={{ flex: 1 }}>
                            <GRNDetailsBarChart />
                        </div>
                        <div style={{ flex: 1 }}>
                            <GRNGaugeChart />
                        </div>
                    </div>
                </>
            )}

            {/* QR Code Quality Control Overview Section */}
            {matchesSearch(sections.qcOverview) && (
                <>
                    <div style={{ marginTop: '20px' }}>
                        <Divider style={{ margin: '10px 0' }} />
                        <div className='qrCodeOverview' style={{ marginBottom: '0px' }}>
                            <h3 className="section-heading" style={{ margin: 0 }}>QR Code Quality Control Overview</h3>
                        </div>
                    </div>

                    <QRCodeQCOverview />
                    <div style={{ marginTop: '20px' }}>
                        <QRCodeGraph />
                    </div>
                </>
            )}

            {/* Show message when no sections match search */}
            {searchQuery && !Object.values(sections).some(section => matchesSearch(section)) && (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '40px', 
                    color: '#666',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    margin: '20px 0'
                }}>
                    <h3>No matching sections found</h3>
                    <p>Try adjusting your search term or clear the search to see all sections</p>
                </div>
            )}
        </div>
    </div>
);
};

export default Dashboard;