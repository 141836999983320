import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './values/global.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// Add this to your main App.js or index.js
if (process.env.NODE_ENV === 'development') {
  const consoleError = console.error;
  console.error = (...args) => {
      if (
          typeof args[0] === 'string' &&
          args[0].includes('ResizeObserver loop completed with undelivered notifications.')
      ) {
          return;
      }
      consoleError(...args);
  };
}