import React, { useEffect, useState } from 'react';
import { Badge, Avatar, Divider } from '@mui/material';
import notificationsImg from '../../../assets/icons/notification.png';
import emailImg from '../../../assets/icons/email.png';

interface HeaderProps {
  userName: string;
  userEmail: string;
}

const Header = () => {
  const [userData,setUserData] = useState<any>({});
  const formatDateTime = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() >= 12 ? 'PM' : 'AM';

    const getOrdinalSuffix = (day: number): string => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const ordinalDay = `${day}${getOrdinalSuffix(day)}`;
    return `${ordinalDay} ${month} ${year} | ${hours}:${minutes} ${period}`;
  };

  const getUserData = () => {
    let tempData:any = sessionStorage.getItem("userData");
    if(tempData){
      tempData = JSON.parse(tempData);
      setUserData((prev:any)=>({...prev,...tempData}))
    }
  }

  useEffect(()=>{
    getUserData();
  },[])

  return (
    <div className="dashboard-header">
      <div className='headerContainer'>
        <div className='welcomeContainer'>
          <h2 className='welcomeMessage'>Welcome Back, {userData?.user_name || ''}</h2>
        </div>
        <div className='userInfo'>
          <p className='welcomeText'>{formatDateTime(new Date())}</p>
          {/* <div className='notificationContainer'>
            <Badge variant="dot" sx={{ '& .MuiBadge-dot': { backgroundColor: '#FF0000', top: 5, right: 5 } }}>
              <img src={notificationsImg} alt="Notifications" width="26" height="26" className="icon outlinedIcon" />
            </Badge>
          </div>
          <div className='emailContainer'>
            <Badge badgeContent={4} sx={{ '& .MuiBadge-badge': { backgroundColor: '#007AFF', color: 'white' } }}>
              <img src={emailImg} alt="Email" width="28" height="20" className="icon outlinedIcon" />
            </Badge>
          </div> */}
          {/* <Avatar alt='' src="/path/to/avatar.jpg" /> */}
          <div className='userDetails'>
            <p className='userName'>{userData?.user_name}</p>
            <p className='userEmail'>{userData?.user_email}</p>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default Header;