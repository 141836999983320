import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
} from '@mui/material';
import Header from '../dashboard/components/Header';
import BackButton from '../../components/BackButton';

const TwoFactorAuthentication: React.FC = () => {
    const userType = sessionStorage.getItem('userType'); // Retrieve userType from session storage
    const data = [
        { userType: 'Vendor', status: 'Active' },
        { userType: 'Admin', status: 'Inactive' },
        { userType: 'Analytics', status: 'Inactive' },
    ];

    // Filter rows based on userType
    const filteredData =
        userType === 'vendor'
            ? data.filter((row) => row.userType === 'Vendor')
            : data;

    return (
        <div>
            <Header />

            <Box
                sx={{
                    padding: '70px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Page Title with Back Button */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                    }}
                >
                    <BackButton redirectTo="/security-settings" />
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            flexGrow: 1, // Allows the title to take up remaining space
                        }}
                    >
                        Two Factor Authentication
                    </Typography>
                </Box>

                {/* Table */}
                <TableContainer
                    component={Paper}
                    sx={{
                        width: '80%',
                        maxWidth: '600px',
                        boxShadow: 3,
                        marginTop: '20px',
                    }}
                >
                    <Table sx={{ minWidth: 400, border: '1px solid #ccc' }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell
                                    sx={{ fontWeight: 'bold', border: '1px solid #ccc' }}
                                >
                                    User Type
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: 'bold', border: '1px solid #ccc' }}
                                >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:hover': { backgroundColor: '#f9f9f9' } }}
                                >
                                    <TableCell sx={{ border: '1px solid #ccc' }}>
                                        {row.userType}
                                    </TableCell>
                                    <TableCell sx={{ border: '1px solid #ccc' }}>
                                        {row.status}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default TwoFactorAuthentication;
