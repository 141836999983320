import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CustomTable, { ColumnConfig } from "../../../components/CustomTable";
import Header from '../../dashboard/components/Header';
import BackButton from '../../../components/BackButton';
import excelIcon from '../../../assets/excel.png';
import { getQrSkuWiseReport, fetchUsers } from '../../../utils/services/apiService';

interface PurchaseOrderItem {
    item_code: string;
    description: string;
    qr_code_count: string;
    scanned_qr_count: string;
    inner_qr_count: string;
    outer_qr_count: string;
    points: string;
    conversion_rate: number;
}

interface User {
    user_id: number;
    user_full_name: string;
    user_email: string;
    user_type: string;
}

const SKUWiseQRGeneratedTillDate: React.FC = () => {
    const [data, setData] = useState<PurchaseOrderItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [exportStatus, setExportStatus] = useState<string | null>(null);
    const [isFilterDialogOpen, setFilterDialogOpen] = useState(false);
    const [year, setYear] = useState('2024');
    const [vendorId, setVendorId] = useState('');
    const [vendors, setVendors] = useState<User[]>([]);
    const userType = sessionStorage.getItem('userType');
    const years = ['2021', '2022', '2023', '2024'];

    useEffect(() => {
        loadPurchaseOrderData();
        if (userType === 'admin' || userType === 'analytics') {
            loadVendors();
        }
    }, []);

    const loadVendors = async () => {
        try {
            const response = await fetchUsers();
            if (response.status === 200) {
                // Filter only vendors from the user list
                const vendorList = response.data.data.filter(
                    (user: User) => user.user_type.toLowerCase() === 'vendor'
                );
                setVendors(vendorList);
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    const loadPurchaseOrderData = async (filterYear?: string, filterVendorId?: string) => {
        setLoading(true);
        try {
            const response = await getQrSkuWiseReport({
                year: parseInt(filterYear || year),
                exportExcel: false,
                vendor_id: filterVendorId ? parseInt(filterVendorId) : (vendorId ? parseInt(vendorId) : 0),
            });
            if (response.data && response.data.purchaseOrdersItemDetails) {
                setData(response.data.purchaseOrdersItemDetails);
            }
        } catch (error) {
            console.error('Error fetching purchase order data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async () => {
        setExportStatus('Exporting data...');
        try {
            const response = await getQrSkuWiseReport({
                year: parseInt(year),
                exportExcel: true,
                vendor_id: vendorId ? parseInt(vendorId) : 0,
            });
    
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
    
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'qr_sku_wise_report.xlsx';
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            URL.revokeObjectURL(url);
    
            setExportStatus('Export successful! The data has been downloaded.');
            setTimeout(() => setExportStatus(null), 5000);
        } catch (error) {
            console.error('Error exporting data:', error);
            setExportStatus('Export failed. Please try again later.');
            setTimeout(() => setExportStatus(null), 5000);
        }
    };

    const handleFilterDialogOpen = () => setFilterDialogOpen(true);
    const handleFilterDialogClose = () => setFilterDialogOpen(false);

    const handleApplyFilter = () => {
        loadPurchaseOrderData(year, vendorId);
        handleFilterDialogClose();
    };

    const columns: ColumnConfig[] = [
        { field: 'item_code', headerName: 'Item Code', width: 180 },
        { field: 'description', headerName: 'Description', width: 280 },
        { field: 'qr_code_count', headerName: 'QR Code Count', width: 150 },
        { field: 'scanned_qr_count', headerName: 'Scanned QR Count', width: 150 },
        { field: 'inner_qr_count', headerName: 'Inner QR Count', width: 150 },
        { field: 'outer_qr_count', headerName: 'Outer QR Count', width: 150 },
        { field: 'points', headerName: 'Points', width: 120 },
        { field: 'conversion_rate', headerName: 'Conversion Rate', width: 150 },
    ];

    return (
        <div className="screen">
            <Header />
            <div className="table">
                <div className="table-header-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex" alignItems="center" gap={2}>
                <BackButton redirectTo="/reports" />
                    <Typography variant="h6" className="breadcrumb">
                    SKU Wise QR Generated Till Date
                    </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                    <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilterDialogOpen}
                            className="filter-button"
                        >
                            <FilterAltIcon style={{ color: 'white' }} />
                            Filter
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'whitesmoke', color: '#007d3c', fontWeight: 'bold', border: '1px solid #ccc' }}
                            onClick={handleExport}
                            className="export-button"
                        >
                            <img src={excelIcon} alt="Excel Icon" style={{ width: 20, height: 20, marginRight: 8 }} />
                            Export
                        </Button>
                        
                    </Box>
                </div>

                {exportStatus && (
                    <Typography variant="body2" className="export-status" style={{ marginTop: 16 }}>
                        {exportStatus}
                    </Typography>
                )}

                <div className="custom-table">
                    <CustomTable
                        editMode="row"
                        data={data}
                        columns={columns}
                        loading={loading}
                        getRowId={(data: any) => data.item_code}
                    />
                </div>

                <Dialog open={isFilterDialogOpen} onClose={handleFilterDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>Filter Purchase Order Data</DialogTitle>
                    <DialogContent>
                        <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                label="Year"
                            >
                                {years.map((yearOption) => (
                                    <MenuItem key={yearOption} value={yearOption}>
                                        {yearOption}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {(userType === 'admin' || userType === 'analytics') && (
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel>Vendor</InputLabel>
                                <Select
                                    value={vendorId}
                                    onChange={(e) => setVendorId(e.target.value)}
                                    label="Vendor"
                                >
                                    <MenuItem value="">All Vendors</MenuItem>
                                    {vendors.map((vendor) => (
                                        <MenuItem key={vendor.user_id} value={vendor.user_id.toString()}>
                                            {vendor.user_full_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFilterDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleApplyFilter} color="primary" variant="contained">
                            Apply Filter
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default SKUWiseQRGeneratedTillDate;
