import React, { createContext, useContext, useState, useEffect } from "react";

interface GeoLocation {
  latitude: number | null;
  longitude: number | null;
  permissionStatus: "granted" | "prompt" | "denied";
}

const GeoContext = createContext<GeoLocation>({
  latitude: null,
  longitude: null,
  permissionStatus: "prompt",
});

export const GeoProvider = ({ children }: { children: React.ReactNode }) => {
  const [location, setLocation] = useState<GeoLocation>({
    latitude: null,
    longitude: null,
    permissionStatus: "prompt",
  });

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout | null = null;

    const handleGeoChange = () => {
      navigator.permissions.query({ name: "geolocation" }).then((permissions) => {
        const newPermissionStatus = permissions.state;

        // Update permission status only if it has changed
        if (newPermissionStatus !== location.permissionStatus) {
          setLocation((prev) => ({
            ...prev,
            permissionStatus: newPermissionStatus,
          }));
        }

        if (newPermissionStatus === "granted" || newPermissionStatus === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              // Clear previous debounce timeout
              if (debounceTimeout) clearTimeout(debounceTimeout);

              debounceTimeout = setTimeout(() => {
                const newLocation = {
                  latitude: pos.coords.latitude,
                  longitude: pos.coords.longitude,
                  permissionStatus: newPermissionStatus,
                };

                // Update location only if it has changed
                if (
                  newLocation.latitude !== location.latitude ||
                  newLocation.longitude !== location.longitude
                ) {
                  setLocation(newLocation);

                  // Store location in sessionStorage
                  sessionStorage.setItem("latitude", newLocation.latitude.toString());
                  sessionStorage.setItem("longitude", newLocation.longitude.toString());
                }
              }, 500); // Debounce for 500ms
            },
            (error) => {
              console.error("Geolocation error:", error);

              // If an error occurs, mark permission status as denied
              if (location.permissionStatus !== "denied") {
                setLocation((prev) => ({
                  ...prev,
                  permissionStatus: "denied",
                }));
              }
            }
          );
        }
      });
    };

    // Initial geolocation fetch
    handleGeoChange();

    // Set up interval to check geolocation every 15 seconds
    const intervalId = setInterval(handleGeoChange, 15000);

    return () => {
      clearInterval(intervalId);
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [location]);

  return <GeoContext.Provider value={location}>{children}</GeoContext.Provider>;
};

export const useGeoContext = () => useContext(GeoContext);
