import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Logs.css';
import BackButton from '../../components/BackButton';
import Header from '../../pages/dashboard/components/Header';

const LogsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleViewLog = (logId: number) => {    
    if (logId === 1) {
      navigate('/qr-generation-logs');
    } else if (logId === 2) {
      navigate('/po-upload-logs');
    } 
    else if (logId === 3) {
      navigate('/access-request-logs');
    }else if (logId === 4) {
      navigate('/communication-logs');
    }else if (logId === 5) {
      navigate('/user-logs');
    }
    else {
      navigate(`/log/${logId}`);
    }
  };

  const logs = [
    { id: 1, message: 'Qr Generation Logs' },
    { id: 2, message: 'Purchase Order Upload Logs' },
    { id: 3, message: 'Request Access Logs' },
    { id: 4, message: 'Communication Logs' },
    { id: 5, message: 'User Logs' },
    // { id: 6, message: 'SAP API Logs' },
    // { id: 7, message: 'User Session Logs' },
  ];

  return (
    <div>
      <Header />
      <div className="logs-container">
        <BackButton redirectTo="/security-settings" />
        <h2 className="logs-title">System Activity Logs</h2>
        
        {logs.map(log => (
          <div key={log.id} className="log-section">
            <span className="log-name">{log.message}</span>
            <button 
              className="view-log-btn" 
              onClick={() => handleViewLog(log.id)}
            >
              View Log
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogsPage;
