import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { AuthProvider } from "./utils/AuthContext";
import { ErrorProvider } from "./components/ErrorContext";
import { GeoProvider, useGeoContext } from "./components/GeoContext";
import ForbiddenPopup from "./components/ForbiddenPopup";
import ForbiddenGeoLocation from "./components/ForbiddenGeoLocation";

const App: FC = () => {
  return (
    <AuthProvider>
      <ErrorProvider>
        <GeoProvider>
        <ForbiddenPopup/>
          <GeoLocationHandler />
        </GeoProvider>
      </ErrorProvider>
    </AuthProvider>
  );
};

const GeoLocationHandler: FC = () => {
  const { permissionStatus } = useGeoContext();

  return (
    <>
      <ForbiddenGeoLocation
        isOpen={permissionStatus === "denied"}
        onClose={() => {
        }}
      />
      <Router>
        <AppRoutes />
      </Router>
    </>
  );
};

export default App;
